import React from 'react'
import './App.css'

function App () {
  return (
    <div className='App'>
      <header className='App-header'>
        <p>
          WIP, aren't we all?
        </p>
      </header>
    </div>
  )
}

export default App
